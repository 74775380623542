import React from "react";

import { StaticImage } from "gatsby-plugin-image";

import "@fontsource/asap";
import "@fontsource/open-sans";

import { Navigation, Footer, Date } from "./index";


const Body = (): JSX.Element => {
  return (
    <div className="bg-gray-100 p-10 text-black" style={{ fontFamily: "Open Sans" }}>
      <div className="flex flex-wrap lg:flex-nowrap mx-4 mb-16">
        <div className="lg:flex-col lg:w-2/3 space-y-4">
          <h2 className="text-3xl font-bold text cansativa-organics-gray-800" style={{ fontFamily: "Asap" }}>How Does CBD Work?</h2>
          <p>CBD interacts with the endocannabinoid system of the body. It impacts the regulatory mechanism, which is made up of naturally occurring cannabis-like molecules — endocannabinoids. Although both CBD and THC interact with the endocannabinoid system via CB1 and CB2 receptors, their impact differs a lot.</p>
          <p>While THC attaches itself to CB1 receptors, which are mainly present the brain and are involved with cognition, memory, motor skills, and pain, it inhibits the release of neurotransmitters and increases the release of other compounds, altering normal functioning. Scientists thought CBD did the same thing, but with CB2 receptors. The exact way CBD affects our bodies is still to be explored. However, scientists believe CBD encourages the body to produce more endocannabinoids.</p>
          <p> According to another version, CBD interacts with the human body using the endocannabinoid system, resulting in different effects on the mind and body. It activates adenosine receptors, decreasing levels of stress and anxiety.</p>
          <p>Another CBD benefit is its ability to bind to the neural receptors which control temperature and inflammation. Thus, the cannabidiol may produce an anti-inflammatory effect.</p>
        </div>
        <StaticImage
          alt="Marijuana leaves and jars."
          className="lg:flex-col lg:w-1/3 mt-8 mx-6"
          src="../../../../images/cansativa-human-diagram.jpg"
          loading="eager"
          objectFit="fill"
        />
      </div>
      <div className="mt-16 flex flex-wrap lg:flex-nowrap">
        <StaticImage
          alt="Marijuana leaves and jars."
          className="lg:flex-col mx-4"
          src="../../../../images/cansativa-cbd-pills.jpg"
          loading="eager"
          objectFit="fill"
        />
        <div className="lg:flex-col mx-4 w-full">
          <h2 className="text-3xl font-bold text cansativa-organics-gray-800" style={{ fontFamily: "Asap" }}>Is CBD Safe?</h2>
          <p className="mt-6">The data on the safety of CDB is limited. The data that are available point to possible risks that people must consider before taking CBD for any reason.</p>
          <p className="mt-4">Some side effects of CBD are noticeable, including:</p>

          <ul className="ml-5"style={{ listStyleType: "disc" }}>
            <li>Sleepiness</li>
            <li>Diarhea</li>
            <li>Changes in appetite</li>
            <li>Irritability</li>
          </ul>

          <p className="mt-4">If you plan to use products containing CBD, talk to your doctor.\</p>
          <p className="text-sm mt-6 italic flex flex-wrap">National Institute on Drug Abuse; National Institutes of Health; U.S. Department of Health and Human Services. (2019). Marijuana as Medicine. <a className="w-full overflow-hidden"
          href="https://www.drugabuse.gov/publications/drugfacts/marijuana-medicine" target="_blank" rel="noopener noreferrer">https://www.drugabuse.gov/publications/drugfacts/marijuana-medicine</a></p>
        </div>
      </div>
    </div>
  );
};

const AboutCBDPage = (): JSX.Element => {
  return (
    <div className="flex flex-col min-h-screen bg-cansativa-organics-gray-100" style={{ fontFamily: "Abel" }}>
      <Navigation />
      <Body />
      <Date />
      <Footer />
    </div>
  );
};

export default AboutCBDPage;
